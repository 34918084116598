
























/*
* 작은 화면일때 글씨가 잘리는 경우 대응
* */
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class StarScoreInfo extends Vue {
  @Prop({
    required: true
  })
  public state!: boolean;
}
