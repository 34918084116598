






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Translator } from 'vue-google-translate';

@Component({ components: { Translator } })
export default class MainFooter extends Vue {
  
}
