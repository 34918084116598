




































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StarScoreInfo from '@/components/popup/StarScoreInfo.vue';
import { EventBus } from '@/config/config';

@Component({ components: { StarScoreInfo } })
export default class MainInfo extends Vue {
  @Prop({
    required: true
  })
  public place!: any;

  @Prop()
  public reservation!: any;

  @Prop({
    required: true
  })
  public simpleLayout!: boolean;
  @Prop()
  public selectLang!: any;

  public infoState: boolean = false;

  public NumberFormat(num) {
    if (!num && num !== 0) return 0;
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public getStar(num) {
    return (num / 20).toFixed(1);
  }

  public copyNoti() {
    this.$q.notify('주소가 복사되었습니다.');
  }
  // 길찾기
  public mapMove() {
    const placeName = encodeURIComponent(this.place.name);
    const appName = encodeURIComponent('https://m.poing.io/');
    const map1 = this.place.coordinate ? this.place.coordinate[0] : null;
    const map2 = this.place.coordinate ? this.place.coordinate[1] : null;

    const url = `route/walk?dlat=${map1}&dlng=${map2}&dname=${placeName}&appname=${appName}`;
    const iosUrl = `nmap://${url}`;
    const androidUrl = `intent://${url}#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end`;
    const webUrl = `https://m.search.naver.com/search.naver?query=${encodeURIComponent('빠른길찾기')}`
      + `&nso_path=placeType^place;name^;address^;latitude^;longitude^;code^|type^place;`
      + `name^${placeName};latitude^${map1};longitude^${map2};code^|objtype^path;by^`;

    if (this.$q.platform.is.mobile) {
      if (this.$q.platform.is.ios) { //ios
        this.iosMapMove(iosUrl);
      } else { //android
        location.href = androidUrl;
      }
    } else { //desktop
      location.href = webUrl
    }
  }
  public iosMapMove(url: any) {
    const clickedAt = +new Date();
    location.href = url;
    setTimeout(() => {
      if (+new Date() - clickedAt < 2000) {
        location.href = 'http://itunes.apple.com/app/id311867728?mt=8';
      }
    }, 1500);
  }

  get taxiUrl() {
    const map1 = this.place.coordinate ? this.place.coordinate[0] : null;
    const map2 = this.place.coordinate ? this.place.coordinate[1] : null;
    return `http://t.kakao.com/launch?type=taxi&dest_lat=${map1}&dest_lng=${map2}`;
  }

  @Watch('infoState')
  public onOff() {
    if (this.infoState === false) {
      document.querySelector('html' as any).classList.remove('open-modal');
    } else {
      document.querySelector('html' as any).classList.add('open-modal');
    }
  }
}
