import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { slice } from 'lodash';

@Component
export default class DataFormat extends Vue {
  // 가격
  public NumberFormat(point: number) {
    if (!point && point !== 0) return 0;
    return point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  // 5개 별점 %
  public starPercentage(star: number) {
    if (star === 70) {
      return star + 1 + '%';
    } else if (star === 90) {
      return star + 2 + '%';
    } else if (star === 67) {
      return star + 3 + '%';
    } else {
      return star + '%';
    }
  }
  // 텍스트 줄바꿈
  public lineBreak(text: string) {
    if (!text) return '';
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
  // 시간 초단위 제거
  public formatTime(time: any) {
    const min = time.split(':');
    return `${min[0]}:${min[1]}`;
  }
  // 시간 오전 오후 변환
  public formatAmPm(time: any) {
    const h = Number(time.split(':')[0]);
    const hour = h >= 13 ? h - 12 : h;
    const min = time.split(':')[1];
    if (h < 12) {
      return `오전 ${hour < 10 ? '0' : ''}${hour}:${min}`;
    } else {
      return `오후 ${('0' + hour).slice(-2)}:${min}`;
    }
  }
  /* 예약 정보 시간 변환
   * type = date -> yyyy년 m월 dd일 (w)
   * type(default) = time -> m월 d일 (w) 오전 hh:mm
   * type = simple -> m월 d일 (w)
   * type = year -> yyyy년 m월 dd일 (w) 오전 hh:mm
   */
  public reservationDate(t: any, type: any = '') {
    const str = moment(t);
    const year = str.format('YYYY');
    const month = str.format('M');
    const day = str.format('D');
    const week = str.format('dd');
    let hour: any = str.format('HH');
    const minute = str.format('mm');

    if (hour === '00') {
      hour = 12;
    }
    if (hour > 12) {
      hour = `오후 ${hour - 12}`;
    } else if (hour === '12') {
      hour = `오후 ${hour}`;
    } else {
      hour = `오전 ${hour}`;
    }

    if (type === 'date') {
      return `${year}년 ${month}월 ${day}일 (${week})`;
    } else if (type === 'simple') {
      return `${month}월 ${day}일 (${week})`;
    } else if (type === 'year') {
      return `${year}년 ${month}월 ${day}일 (${week}) ${hour}:${minute}`;
    } else if (type === 'noTime') {
      if (Number(month) < 10) {
        if (Number(day) < 10) {
          return `${year}-0${month}-0${day}`;
        } else {
          return `${year}-0${month}-${day}`;
        }
      } else {
        if (Number(day) < 10) {
          return `${year}-${month}-0${day}`;
        } else {
          return `${year}-${month}-${day}`;
        }
      }
    } else if (type === 'simpleM') {
      return `${year}-${month}`;
    } else if (type === 'refund') {
      if (Number(month) < 10) {
        if (Number(day) < 10) {
          return `${year}년 0${month}월 0${day}일`;
        } else {
          return `${year}년 0${month}월 ${day}일`;
        }
      } else {
        if (Number(day) < 10) {
          return `${year}년 ${month}월 0${day}일`;
        } else {
          return `${year}년 ${month}월 ${day}일`;
        }
      }
    } else {
      return `${month}월 ${day}일 (${week}) ${hour}:${minute}`;
    }
  }
  /* 환불 % 반환
   * date = 예약 등록일
   * type = 보증금 환불 규정 (strict, normal, flexible)
   */
  public refundPercent(reservation: any, deposit: any) {
    const now = this.$moment(new Date()).format('YYYY-MM-DD');
    const startDate = this.$moment(String(reservation.reserv_start)).format(
      'YYYY-MM-DD'
    );
    const result = this.$moment(startDate).diff(now, 'days');

    // 방문 했을 경우에는 100% 환불이기 때문에 100으로 return
    if (reservation.reserv_visit_status === 'appeared') {
      return 100;
    }
    if (deposit) {
      let price = deposit.paid_price;
      let refundPrice = deposit.refund_price;
      if (deposit.paid_price === 0 && deposit.paid_price_usd !== 0) {
        price = deposit.paid_price_usd;
        refundPrice = deposit.refund_price_usd;
      }
      if (refundPrice !== 0) {
        return (refundPrice / price) * 100;
      }
      switch (deposit.cancel_policy.type) {
        case 'strict':
          if (deposit && price === refundPrice) {
            return 100;
          }
          if (result < 0 || result === 0) {
            return 0;
          } else if (result === 1) {
            return 20;
          } else if (result === 2) {
            return 50;
          } else {
            return 100;
          }
        case 'normal':
          if (deposit && price === refundPrice) {
            return 100;
          }
          if (result < 0) {
            return 0;
          } else if (result === 0) {
            return 20;
          } else if (result === 1) {
            return 50;
          } else {
            return 100;
          }
        case 'flexible':
          if (deposit && price === refundPrice) {
            return 100;
          }
          if (result < 0) {
            return 0;
          } else if (result === 0) {
            return 50;
          } else {
            return 100;
          }
        case 'custom':
          if (deposit && price === refundPrice) {
            return 100;
          }
          for (const [key, list] of deposit.cancel_policy.list.entries()) {
            if (result <= list.lead_day && list.lead_day === -1) {
              // noshow
              return list.refund_percent;
            } else if (result === list.lead_day && list.lead_day === 0) {
              // 당일
              return list.refund_percent;
            } else if (
              result <= list.lead_day ||
              result < deposit.cancel_policy.list[key + 1].lead_day
            ) {
              return list.refund_percent;
            } else if (
              result >=
              deposit.cancel_policy.list[deposit.cancel_policy.list.length - 1]
                .lead_day
            ) {
              return deposit.cancel_policy.list[
                deposit.cancel_policy.list.length - 1
              ].refund_percent;
            }
          }
        default:
          return 0;
      }
    }
  }
  // 취소/방문 - 환급
  public completeRefundPercent(deposit: any) {
    let price = deposit.paid_price;
    let refund = deposit.refund_price;
    if (deposit.paid_price === 0 && deposit.paid_price_usd !== 0) {
      price = deposit.paid_price_usd;
      refund = deposit.refund_price_usd;
    }
    return (refund / price) * 100;
  }
  // 판매 시작 전 여부
  public startState(date: any) {
    const now = moment();
    const startDate = moment(date);
    // const nowHour = now.format('DDHH');
    // const startDateHour = startDate.format('DDHH');
    // const result = startDate.diff(now, 'days');
    if (now.isBefore(startDate)) {
      return true;
    } else {
      return false;
    }
  }

  // 판매 종료 여부
  public endState(date: any) {
    const now = moment();
    const endDate = moment(date);
    // const result = endDate.diff(now, 'days');
    if (now.isAfter(endDate)) {
      return true;
    } else {
      return false;
    }
  }
  // 전화번호를 010-1234-5678 형태로 변경
  public phone(str: string) {
    return str.replace(
      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
      '$1-$2-$3'
    );
  }
}
